import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ColorVariants } from 'core/theme/entities';
import {
  ButtonIcon,
  ButtonLabel,
  ButtonLoadingIndicator,
  ButtonStyled,
  StyledImpulseButton,
  StyledImpulseScaleButton,
} from './styled';

export type ButtonDeprecatedProps = {
  variant?: ColorVariants;
  className?: string;
  icon?: ReactNode;
  loading?: boolean;
  impulse?: boolean;
  fullWidth?: boolean;
  impulseScale?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonDeprecated = ({
  children,
  onClick,
  variant = 'primary',
  className,
  icon,
  loading,
  disabled,
  impulse = false,
  impulseScale = false,
  fullWidth = false,
  type = 'button',
}: ButtonDeprecatedProps) => {
  const renderIcon = () => {
    if (loading) {
      return (
        <ButtonIcon>
          <ButtonLoadingIndicator size={20} />
        </ButtonIcon>
      );
    }

    if (icon) {
      return <ButtonIcon>{icon}</ButtonIcon>;
    }

    return null;
  };

  if (impulse) {
    return (
      <StyledImpulseButton
        disabled={loading || disabled}
        className={className}
        type={type}
        onClick={onClick}
        variant={variant}
      >
        <ButtonLabel>
          <>
            {renderIcon()}
            {children}
          </>
        </ButtonLabel>
      </StyledImpulseButton>
    );
  }

  if (impulseScale) {
    return (
      <StyledImpulseScaleButton
        className={className}
        disabled={loading || disabled}
        type={type}
        onClick={onClick}
        variant={variant}
      >
        <ButtonLabel>
          <>
            {renderIcon()}
            {children}
          </>
        </ButtonLabel>
      </StyledImpulseScaleButton>
    );
  }

  return (
    <ButtonStyled
      disabled={loading || disabled}
      className={className}
      type={type}
      onClick={onClick}
      variant={variant}
      fullWidth={fullWidth}
    >
      <ButtonLabel>
        <>
          {renderIcon()}
          {children}
        </>
      </ButtonLabel>
    </ButtonStyled>
  );
};

export default ButtonDeprecated;
