import { HTMLAttributes } from 'react';
import { ButtonStyled, CrossIconStyled } from './styled';

type ButtonCloseProps = HTMLAttributes<HTMLButtonElement>;

const ButtonClose = (props: ButtonCloseProps) => {
  return (
    <ButtonStyled {...props}>
      <CrossIconStyled name="cross" />
    </ButtonStyled>
  );
};

export default ButtonClose;
