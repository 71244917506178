import styled from 'styled-components';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';
import { TextButton } from 'core/common/components/ButtonDeprecated';
import Link from 'core/common/components/Link';
import Icon from 'core/svg-sprites/ui/Icon';
import { ColorVariants } from 'core/theme/entities';

export const IconStyled = styled(Icon)<{ variant?: ColorVariants }>`
  position: absolute;
  right: 0;
  fill: ${({ variant, theme }) => {
    if (variant === 'secondary') {
      return theme.colors.typography100;
    }

    return theme.colors.typography700;
  }};
  cursor: pointer;
`;

export const CloseButtonStyled = styled(ButtonClose)`
  margin-bottom: 20px;

  ${CrossIconStyled} {
    width: 24px;
    height: 24px;
  }
`;

export const ContainerStyled = styled.div`
  width: 312px;
  padding: 20px;
`;

export const NavLinkStyled = styled(Link)`
  display: block;
  margin-bottom: 10px;
`;

export const ButtonStyled = styled(TextButton)`
  display: block;
  margin: 0 0 10px;
  color: inherit;
`;
