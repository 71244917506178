import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { BASE_ROUTES } from 'core/common/constants';
import { useCookieConsent } from 'core/common/hooks';
import { ColorVariants } from 'core/theme/entities';
import { europeanUnionCountries, Locales, Regions } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import {
  ButtonStyled,
  CloseButtonStyled,
  ContainerStyled,
  IconStyled,
  NavLinkStyled,
} from './styled';

type HeaderMenuProps = {
  variant?: ColorVariants;
};

export const HeaderMenu = ({ variant = 'primary' }: HeaderMenuProps) => {
  const { toggleReconsent } = useCookieConsent();

  const [isOpened, setIsOpened] = useState(false);

  const onControlClick = () => {
    setIsOpened((prev) => !prev);
  };

  const onClose = () => setIsOpened(false);

  return (
    <>
      <IconStyled name="burger" onClick={onControlClick} variant={variant} width={24} height={24} />
      <Drawer open={isOpened} onClose={onClose}>
        <ContainerStyled>
          <CloseButtonStyled onClick={onClose} />
          <nav>
            <NavLinkStyled href={BASE_ROUTES.TECH_PRIVACY_POLICY}>Privacy policy</NavLinkStyled>
            <NavLinkStyled href={BASE_ROUTES.TECH_TERMS}>Terms of use</NavLinkStyled>
            <NavLinkStyled href={BASE_ROUTES.TECH_PAYMENT_TERMS}>Payment terms</NavLinkStyled>
            <NavLinkStyled href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>
              Subscription terms
            </NavLinkStyled>
            <NavLinkStyled href={BASE_ROUTES.TECH_MONEY_BACK_POLICY}>
              Money back policy
            </NavLinkStyled>
            <RenderOnlyOnClient>
              <Location>
                <LocationOption option={[...europeanUnionCountries, Locales.GB]}>
                  <ButtonStyled onClick={toggleReconsent}>Consent settings</ButtonStyled>
                </LocationOption>
                <LocationOption option={Locales.OTHER}>{null}</LocationOption>
              </Location>
            </RenderOnlyOnClient>
            {/*<NavLink href={BASE_ROUTES.TECH_DELIVERY_POLICY}>Delivery & Return Policy</NavLink>*/}
            <LocationRegion>
              <LocationRegionOption option={Regions.CALIFORNIA}>
                <NavLinkStyled href={BASE_ROUTES.TECH_PERSONAL_INFO_POLICY}>
                  Personal information
                </NavLinkStyled>
              </LocationRegionOption>
            </LocationRegion>
            <NavLinkStyled href={BASE_ROUTES.TECH_FAQ}>FAQ</NavLinkStyled>
            <NavLinkStyled href={BASE_ROUTES.TECH_CONTACT_US}>Contact us</NavLinkStyled>
          </nav>
        </ContainerStyled>
      </Drawer>
    </>
  );
};

export default HeaderMenu;
